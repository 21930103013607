import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ExploreAndSearchResponse,
    ProfileSettingsResult,
    TrekHeadersResult,
    UploadProfilePicResult
} from '../../../typings/api';
import { Observable } from 'rxjs';
import { LoggedInUser, User } from '../../auth/models/auth.model';
import { environment } from '@env';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) {}

    getProfileData(userId: string): Observable<User> {
        return this.http.get<User>(`${environment.GATE_URL}/user/${userId}`);
    }

    getProfileTreks(): Observable<TrekHeadersResult> {
        const config = {
            params: {
                since: '0'
            }
        };

        return this.http.get<TrekHeadersResult>(
            `${environment.GATE_URL}/trek/my-treks`,
            config
        );
    }

    getUserTreks(
        userId: string,
        limit: number,
        offset: number
    ): Observable<ExploreAndSearchResponse> {
        const filterQuery = {
            query_filter: {
                filter: [
                    {
                        m_key: 'user_id',
                        m_value: userId
                    }
                ],
                limit: {
                    trek: limit,
                    site: 0,
                    touring_area: 0,
                    user: 0
                },
                offset: {
                    trek: offset,
                    site: 0,
                    touring_area: 0,
                    user: 0
                },
                order_by: { start_date: -1 }
            }
        };

        return this.http.post<ExploreAndSearchResponse>(
            `${environment.GATE_URL_2}/search/old/v2`,
            filterQuery
        );
    }

    updateProfileDetails(userId: string, info: LoggedInUser): Observable<{}> {
        const data = {
            name: info.name,
            tag: info.tag
        };
        return this.http.put(`${environment.GATE_URL}/user/${userId}`, data);
    }

    getProfileSettings(): Observable<ProfileSettingsResult> {
        return this.http.get<ProfileSettingsResult>(
            `${environment.GATE_URL}/v2/user/settings`
        );
    }

    updateProfileSettings(data: {
        follow_me: boolean;
        locale: string;
    }): Observable<{}> {
        return this.http.put(`${environment.GATE_URL}/v2/user/settings`, data);
    }

    uploadProfilePic(pic: Blob): Observable<UploadProfilePicResult> {
        const data = new FormData();
        data.append('0', pic);
        data.append('action', 'upload');

        return this.http.post<UploadProfilePicResult>(
            `${environment.MEDIA_URL}/profile_picture`,
            data
        );
    }
}
